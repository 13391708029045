
.introimage {
  width: 112px;
  height: 112px;
  
}

.introimagewide {
  width: 250px;
  height: 150px;
  
}

.header-title{
  color: white;
}
.title-color{
  color:white;
}

.card-figma{
  border-radius: 9.2px;
  padding: 10px;
  color: white;
}
.btn-figma{
  width: 200px;
}
.card-galactic{
  background: linear-gradient(135deg, #40DDFF 0%, #14BAE3 19.24%, #13B1E6 68.64%, #11AADF 81.77%, #0B98C5 100%);

}
.card-traces{
  background: linear-gradient(136.67deg, #FF409A 8.34%, #C438EF 95.26%);
}
.card-journey{
  background: linear-gradient(135deg, #CD40FF 0%, #4A14E3 19.24%, #7013E6 68.64%, #2E11DF 81.77%, #0E0BC5 93.23%);
}
.card-snapshot{
  background: linear-gradient(136.67deg, #FFC940 8.34%, #C438EF 95.26%);
}
.card-kaal{
  background: linear-gradient(136.67deg, #408CFF 8.34%, #C438EF 95.26%);
}

.card-sunmoon{
  background: linear-gradient(136.67deg, #FF7940 8.34%, #EFA638 95.26%);
}

.card-conjunction{
  background: linear-gradient(136.67deg, #448CFF 8.34%, #5e0679 95.26%);
}
