@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap");
body {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 0px !important;
  overflow-x: hidden;
  background-color: white;
}
.container {
  max-width: 1240px !important;
}
.container-fluid {
  padding-left: 2px;
  padding-right: 2px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 15px;
  padding-right: 15px;
}
.w-100 {
  width: 100% !important;
}
.relative {
  position: relative !important;
}
a:hover {
  text-decoration: none !important;
}
.top-header {
  width: 100%;
  height: 62px;
  position: relative;
  display: flex;
  background: url("../assets/img/bgHeader.png");
  background-size: cover;
  z-index: 9;
}

.navbar-nav {
  margin: 0 auto;
  text-align: center;
}

.nav-item2 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.nav-header {
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 9;
}

.navbar-brand {
  display: flex;
}
.navbar-brand span {
  font-size: 14px !important;
  font-weight: 600;
  text-transform: uppercase;
  color: #717171 !important;
  padding: 2px 10px;
}
.logo {
  width: auto;
}
.nav-link {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px !important;
  font-weight: 600;
}
header {
  background-position: 0 -300px !important;
  background-size: cover;
  height: 335px;
  /* margin-bottom: 5rem; */
}
header.masthead {
  z-index: 99;
}
.banner-caption {
  position: relative;
  top: 310px;
  font-size: 18px;
  font-style: italic;
}
.banner-bottom {
  position: relative;
}
.logo-center {
  margin-top: 10px !important;
  text-align: left;
  z-index: 99;
}
.logo-center img{
  width: 370px;
}
.lets-started {
  font-size: 16px;
  font-style: italic;
  color: #ee6565;
}
.services-tab {
  position: absolute;
  top: 0;
  width: 586px;
  height: 706px;
  z-index: 9;
}
.service-menu {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font: 600 22px "Playfair Display", serif;
  color: #2d6e8f;
  text-align: center;
}
.service-menu:hover {
  text-decoration: none;
}
.service-menu h4 {
  font: 600 22px "Playfair Display", serif;
  color: #2d6e8f;
  text-align: center;
}
.nav-tab h4 {
  font: 400 16px "Montserrat", sans-serif;
  color: #000;
  text-align: center;
  margin: 0px;
}

.transition {
  display: block;
  position: absolute;
  top: 30px;
  left: 35%;
}
.panchang {
  display: block;
  position: absolute;
  top: 40%;
  right: 0%;
}
.horoscope {
  display: block;
  position: absolute;
  bottom: 0px;
  left: 42%;
}

.service-spinner {
  position: absolute;
  left: -12%;
  top: -19%;
}
.services-img {
  width: 950px;
}
.about-desc {
  padding: 0px 0;
  text-align: right;
}

.p1 {
  font-size: 14px;
  color: #000;
}
.p2 {
  font-size: 14px;
  color: #a3a3a3;
}
.desc {
  font-size: 16px;
  font-style: italic;
  color: #a3a3a3;
}
.sections-title h3 {
  font: 600 28px "Playfair Display", serif;
  color: #2d6e8f;
  text-align: center;
  margin: 0 auto;
}
.feature-container {
  padding: 0rem 0 3rem;
}
.feature-grid {
  margin: 0 0 20px;
  padding:0 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 24px;
  gap: 24px;
  grid-template-columns: 1fr;
}
.feature-col {
  position: relative;
  flex: 1 1 inherit;
}

.feature-card {
  position: relative;
  width: 200px;
  padding: 25px 20px;
  border-radius: 15px;
  text-align: center;
  box-sizing: border-box;
  background: #fff;
  background-clip: padding-box;
  margin-bottom: 20px;
}

.feature-card h4 {
  font: 400 18px "Montserrat", sans-serif;
  color: #000;
  margin: 15px 0;
  white-space: nowrap;
}
.feature-card h4:hover {
  color: #ee6565;
  /* text-shadow:  0 0 3px rgb(78 77 82 / 12%), 0 0 5px rgb(78 77 82 / 12%); */
}
.more-sections {
  font-size: 16px;
  font-style: italic;
  color: #ee6565;
  vertical-align: text-top;
  position: absolute;
  top: 30%;
  left: 50%;
  white-space: nowrap;
}
.btn-explore {
  display: block;
}

.btn-explore img {
  padding-left: 10px;
}
.fold-top {
  position: absolute;
  top: -2px;
  left: -2px;
}
.fold-bottom {
  position: absolute;
  bottom: -2px;
  right: -2px;
}
.inner-content {
  width: 100%;
  position: relative;
}
.subtitle {
  padding: 12px 25px;
  background: url("../assets/img/approach.png") no-repeat;
  background-size: contain;
  font: 600 14px "Montserrat", sans-serif !important;
  color: #fba84b;
}
.inner-content h2 {
  font: 400 36px "Playfair Display", serif;
  color: #4441fa;
  padding: 12px 0px;
}
.inner-content h2 b {
  font: 600 36px "Playfair Display", serif;
}
.inner-content p {
  font: 400 14px "Montserrat", serif;
  color: #505050;
}

.btn-theme {
  background: #fff;
  border: 1px solid #4441fa;
  border-radius: 6px;
  font: 400 14px "Montserrat", serif;
  color: #4441fa;
  padding: 10px 25px;
  transition: box-shadow 0.3s;
}
.btn-theme:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.insights-container {
  width: 100%;
  position: relative;
  background: url("../assets/img/digital-painting-aryabhatt.png");
  background-size: cover;
  height: 587px;
}
.insights-content {
  width: 100%;
  background: url("../assets/img/bg-insights.png") no-repeat;
  background-size: contain;
  padding: 20px;
  position: absolute;
  top: -24px;
  left: 35%;
}
.insights-journey {
  padding: 15px 50px 10px;
}
.insights-journey .subtitle {
  padding: 12px 25px;
  background: url("../assets/img/insights.png") no-repeat;
  background-size: contain;
  font: 600 14px "Montserrat", sans-serif !important;
  color: #fba84b;
  margin-bottom: 20px;
}
.insights-journey .btn-theme {
  border-color: #c1cace !important;
  color: #000 !important;
}
.newsletter-container {
  width: 100%;
  position: relative;
  background-color: #15325a;
  padding: 5rem 0;
  margin-top: 10px;
}
.newsletter-content {
  position: relative;
}
.newsletter-content h2,
.need-assistance h2 {
  font: 400 36px "Playfair Display", serif;
  color: #fff;
  padding: 12px 0px;
}
.newsletter-content p {
  font: 400 16px "Montserrat", serif;
  color: #e1e1e1;
}
.newsletter-content img {
  position: relative;
  margin-top: -40px;
}
.newsletter-container form ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style: none;
}
.newsletter-container form ul li label {
  font: 400 16px "Montserrat", serif;
  color: #fff;
}
.newsletter-container form ul li input {
  background: none;
  border-radius: 0%;
  border-width: 0 0 1px 0;
  border-color: #e1e1e1;
  font: 400 14px "Montserrat", serif;
  color: #e1e1e1;
  padding-left: 0px !important;
}
.newsletter-container form ul li input:focus {
  background: none;
}
.btn-submit {
  background: linear-gradient(90deg, #f190fc, #4441fa);
  padding: 12px 25px;
  color: #fff;
  font: 400 18px "Montserrat", serif !important;
  transition: box-shadow 0.3s;
}
.btn-submit:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.need-assistance {
  position: relative;
  background-color: #eaeaea;
}
.assistance-content {
  background: linear-gradient(90deg, #90d5fc, #4441fa);
  width: 334px;
  height: 334px;
  border-radius: 100%;
  position: relative;
  top: -35%;
}
.assistance-content .btn-theme {
  background: transparent;
  border: 1px solid #fff;
  padding: 12px 25px;
  color: #fff;
  font: 400 18px "Montserrat", serif !important;
  transition: box-shadow 0.3s;
}
.assistance-content .btn-theme:hover {
  background: #fff;
  color: #4441fa;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.assistance-circle {
  height: 100%;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  text-align: center;
}
.footer-container {
  background: #010037;
  padding: 2rem 0 0 !important;
  color: #c4c4c4;
}
.footer-container ul li {
  list-style: none;
  display: block;
  margin-bottom: 10px;
}
.footer-container ul li a {
  font: 400 12px "Montserrat", serif !important;
  color: #c4c4c4;
}
.footer-logo {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 15px;
}
.social-links a {
  width: auto !important;
}
.btn-social i {
  color: #808080;
  font-size: 22px;
}
.footer-container .social-links {
  margin: 0 auto;
  text-align: center;
}

.footer-address p {
  font: 600 12px "Montserrat", serif !important;
  color: #c4c4c4;
  text-align: left;
}
.footer-address p b {
  width: 60px;
  display: inline-block;
}
.footer-address p span {
  font: 400 12px "Montserrat", serif !important;

  display: inline-block;
}
.contact {
  color: #a1a1a1;
}
.copyright {
  width: 100%;
  border-top: 1px solid #dfdfdf;
  padding: 10px 0;
}
.copyright p {
  margin: 0 auto;
  text-align: center;
  color: #f0f0f0;
}
/* media css */
@media screen and (min-width: 1600px) {
  header.masthead {
    padding: 18% 0 !important;
  }
}

@media (max-width: 992px) {
  .logo-center {
    margin: 0 30px;
  }
  .logo-center img {
    width: 100%;
  }
  #basic-navbar-nav {
    position: absolute;
    width: 100%;
    top: 50px;
    left: 0;
    background: #fff none repeat scroll 0% 0%;
  }
  #basic-navbar-nav a {
    border-bottom: 1px solid #f7f7f7;
  }
  .logo img {
    margin: 0 auto;
  }
  #services {
    margin-bottom: 3rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .services-tab {
    width: 431px;
    height: 521px;
  }
  .services-img {
    width: 680px;
  }
  .service-spinner {
    top: -22%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  header {
    background-size: cover !important;
    background-position: 0px center !important;
    background-repeat: no-repeat !important;
    padding: 0px;
  }
  header.masthead {
    background-position: 0px center !important;
    min-height: 450px !important;
  }
  .navbar-toggler-right {
    position: absolute;
    right: 0;
    top: 0px;
  }
.feature-container{
  display: none;
}
  .feature-card {
    padding: 25px 10px;
  }
  .feature-card h4 {
    font-size: 16px;
  }
  .zodiac-img {
    width: 100%;
  }
  .insights-container {
    height: 450px;
  }
  .insights-content {
    background-image: none;
    position: relative;
    top: 0%;
    left: 0;
  }
  .insights-journey {
    background: #fff;
    border-radius: 8px;
    margin: 5rem 0;
    padding: 15px 20px 10px;
  }
  .need-assistance {
    height: 230px;
  }
  .newsletter-content p {
    font: 400 14px "Montserrat", serif;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
 
  .feature-card h4 {
    font-size: 14px;
  }
  .inner-content {
    text-align: left !important;
  }
  .insights-container {
    padding: 8rem 0 0 !important;
    height: 450px;
  }
  .newsletter-container form ul {
    columns: 2 !important;
    -webkit-columns: 2 !important;
    -moz-columns: 2 !important;
  }
  .assistance-content {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 763px) {
  .logo-center{
    margin-top: 0px !important;
    margin-bottom: 15px;
  }
  .feature-container{
    display: none;
  }
  .feature-col:last-child {
    display: none;
  }
  .d-sm-none {
    display: none;
  }

  .nav-item {
    padding-right: 25px;
  }
  .navbar-toggler-right {
    background: transparent !important;
    position: absolute;
    right: 0;
    top: 0px;
  }
  .navbar-toggler-right i {
    font-size: 17px;
  }
  header {
    background-position: center !important;
  }
  .subtitle {
    text-align: left;
  }
  .services-img,
  .why-us img {
    width: 100%;
  }
  .inner-content {
    text-align: center;
  }
  .insights-container {
    padding: 5rem 0;
    height: auto;
  }
  .insights-content {
    background: none !important;
    position: relative;
    padding: 0;
    top: 0%;
    left: 0;
    transform: none;
    padding-bottom: 10rem;
  }
  .insights-journey {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
  }
  .newsletter-container form ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  .assistance-content {
    position: relative;
    top: 0;
    margin: 10px auto;
  }
  .footer-logo,
  .footer-container .social-links {
    text-align: left;
  }
  .footer-address {
    margin: 15px 0;
  }
}
@media only screen and (max-device-width: 480px) {
  .feature-col:last-child {
    display: none;
  }
  .insights-content {
    padding-bottom: 0rem;
  }
  .assistance-content {
    width: 270px;
    height: 270px;
  }
  .assistance-circle {
    top: 58%;
  }
}
