.profile {
  float: left;
  width: 60px;
  height: 60px;
}
.profile img {
  width: 100%;
  height: 100%;
}
.dob {
  /* width:280px; */
  width: 260px;
  position: absolute;
  top: 0;
  right: 30px;
  display: inline-block;
  text-align: left;
}

.dob-cview {
  position: fixed;
  top: 72px;
  right: 99px;
  display: inline-block;
  text-align: left;
  white-space: nowrap;
}

.dob p,
.dob-cview p {
  margin-top: 12px;
  font-family: "Lucida Bright";
  font-size: 13px;
  font-weight: 400;
  color: #666;
}

.dob h4,
.dob-cview h4 {
  font-weight: 500;
  font-family: "Lucida Bright";
  text-transform: capitalize;
  color: #666;
  margin-top: 0px;
  margin-bottom: 0px;
}
.dob p b,
.dob-cview p b {
  font-weight: 400;
  font-family: "Lucida Bright";
  color: #ff6767ff;
}

.resetLink {
  font-family: "Lucida Bright";
  text-decoration: underline;
  cursor: pointer;
}
.ico-edit {
  /* width: 32px; */
  font-size: 17px;
  margin-top: -2px;
}
