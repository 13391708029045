body {
  margin-left: 1px;
  margin-right: 1px;
  font-family: Lucida Console, Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.holistic {
  font-family: Lucida Bright, Georgia, serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fullscreen {
  z-index: 9999;
  height: 100%;
  width: 100%;
  position: absolute;
  min-height: 100vh;
}

.footer {
  position: "fixed";
}
