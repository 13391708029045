.tableDiv {
  margin-top: 2.5%;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #f4f0ec;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #fff;
}

.table-striped > thead {
  background-color: #f1e6ca;
  color: grey;
}
.table-striped > thead > tr > th {
  padding: 14px;
  font-size: 15px;
}
.table-striped > tbody > tr > td {
  padding: 12px;
  font-size: 16px;
}

.color_neutral {
  color: #808080;
}
.color_friend {
  color: #32cd32;
}
.color_intimate {
  color: #008000;
}
.color_enemy {
  color: #ff5252;
}
.color_bitter {
  color: #cc0000;
}
.color_dash {
  color: #696969;
}

.panel .with-nav-tabs .panel-heading {
  padding: 5px 5px 0 5px;
}
.panel .with-nav-tabs .nav-tabs {
  border-bottom: none;
}
.panel .with-nav-tabs .nav-justified {
  margin-bottom: -1px;
}

/********************************************************************/
/*** PANEL WARNING ***/
.with-nav-tabs.panel-warning .nav-tabs > a,
.with-nav-tabs.panel-warning .nav-tabs > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > a:focus {
  color: #fff;
}
.tab-content {
  padding: 0px;
}
/* .tab-outer-border {
  border: 1px solid #ff6e4a;
  border-top-color: transparent;
} */

.with-nav-tabs.panel-warning .nav-tabs > a,
.with-nav-tabs.panel-warning .nav-tabs > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > a:focus {
  color: #fff;
  background-color: #fff;
  border-color: #ee6565;
  border-bottom-color: transparent;
}
/* .with-nav-tabs.panel-warning .nav-tabs {
  border-color: #ee6565;
} */
.with-nav-tabs.panel-warning .nav-tabs > a {
  color: #ee6565;
}
.with-nav-tabs.panel-warning .nav-tabs > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > a:focus {
  background-color: #ee6565;
}
.with-nav-tabs.panel-warning .nav-tabs > .active > a,
.with-nav-tabs.panel-warning .nav-tabs > .active > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > .active > a:focus {
  color: #fff;
  background-color: #8a6d3b;
}
.with-nav-tabs.panel-warning .nav-tabs > .active {
  color: #fff;
  background-color: #ee6565;
}
.content_table_pr {
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 1.15em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.content_table_pr thead tr {
  background-color: #008585ff;
  color: #ffffff;
}

.content_table_pr th {
  font-weight: bolder;
  text-align: center;
}

.content_table_pr th,
.content_table_pr td {
  padding: 0.7rem 0.4rem 0.7rem 0.4rem;
  margin: 0;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  font-family: "Lucida Bright";
}

.content_table_pr tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content_table_pr tbody tr:last-of-type {
  border-bottom: 2px solid #008585ff;
}

.content_table_pr tbody tr.active-row {
  font-weight: bold;
  color: #008585ff;
}

.content_table_pr tr:hover {
  box-shadow: 0 13px 48px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #008585ff;
  /* -webkit-transform: scale(1.15); */
  color: #fff !important;
}

.reset {
  margin-left: auto;
}
.planet_relations_header {
  color: #666;
  text-align: center;
  margin-top: 10px;
  font-family: "Lucida Bright";
  font-weight: bold;
}

.planet_relations_header_last {
  text-align: center;
  margin-top: 150px;
  font-family: "Lucida Bright";
  font-weight: bold;
}

.left_align {
  text-align: left;
}

.bold {
  text-align: center;
  font-weight: bold;
}

.search {
  top: 50%;
  font-family: "Lucida Bright";
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
  .table-responsive-xs {
    overflow-x: auto;
  }
  .js-plotly-plot .plotly .main-svg {
    width: 100% !important;
  }
}
@media only screen and (max-width: 763px) {
  .table-responsive-xs {
    overflow-x: auto !important;
  }
  .js-plotly-plot .plotly .main-svg {
    width: 100% !important;
  }
}

.pr-images {
  width: 20px;
  margin-right: 30px;
}
.nakshatra-images {
  width: 24px;
  margin-right: 20px;
  height: 24px;
}

.planet-relations-main {
  margin-top: 10px;
}

.fivefold {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
/********************************************************************/
