.YogaWrapper {
  margin-top: 70px;
  margin-left: 240px;
  padding: 10px;
  font-size: 16px;
  color: #666;
}
.yoga-title {
  color: #666;
  font-family: "Lucida Bright";
  font-weight: 700;
  text-align: center;
}
.YogaWrapper select {
  background-color: hsl(0, 0%, 100%);
  border-color: rgba(118, 118, 118, 0.5);
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  width: 100%;
  min-height: 38px;
}
label {
  flex: 0 1 25%;
  font-family: "Lucida Bright";
}
.YogaWrapper input {
  font-size: 16px;
  color: #666;
  border-color: rgba(118, 118, 118, 0.5);
  border-radius: 0px;
}
.YogaWrapper textarea {
  font-size: 16px;
  min-height: 120px;
  border-color: rgba(118, 118, 118, 0.5);
}
.deleteYoga .modal-content{
  padding-left: 0px!important;
}
.deleteYoga .btn-primary{
  font-size: 1rem;
}
.disabled{
  color: gray;
  font-size: 2em;
}
.enabled{
  color: blue;
  font-size: 2em;
}