@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap");
.HeaderDiv {
  text-align: center;
}

.MahaDashaName {
  font: 600 30px "Montserrat", sans-serif;
  color: #666666;
}
.ArdhaTitleImages {
  height: 40px;
  padding-right: 10px;
}

.CurrentDateHeader,
.dasha-name {
  font: 600 14px "Montserrat", sans-serif;
  color: #666666;
  text-align: left;
  margin-bottom: 0;
}
.CurrentDateHeader {
  font: 600 16px "Montserrat", sans-serif !important;
}
.previousButton {
  float: left;
}
.nextButton {
  float: right;
}

.MahaDashaDates,
.PradhamaDashaDates {
  font-size: 1.5em;
  color: #008585ff;
}
.MahaDashaDates span,
.PradhamaDashaDates span {
  font-size: 16px;
  padding-right: 15px;
  color: #666;
}
.MahaDashaDates b,
.PradhamaDashaDates b {
  font: 600 16px "Montserrat", sans-serif;
  color: #008585ff;
}
.ArdhaDashaDiv {
  width: 100%;
  position: relative;
  border-radius: 10px;
}
.ArdhaDashaHeader,
.PradhamaDashaHeader {
  display: flex;
  margin: 0;
  min-height: unset !important;
  border: 1px solid #ff6767ff;
}
.ArdhaDashaHeader .title,
.PradhamaDashaHeader .title {
  flex: 0 1 150px;
  background-color: #ff6767ff;
  color: #fff;
  padding: 6px;
  text-align: center;
  font: 600 18px "Montserrat", sans-serif;
  white-space: nowrap;
}
.ArdhaDashaDates,
.PradhamaDashaDates {
  flex: 1 1 auto;
  text-align: center;
  white-space: nowrap;
}

.ArdhaDashaDates b,
.PradhamaDashaDiv b,
.MahaDashaDates b {
  font: 600 16px "Montserrat", sans-serif;
  color: #008585ff;
  padding-right: 10px;
}
.ArdhaDashaDates span,
.PradhamaDashaDiv span,
.MahaDashaDates span {
  font: 600 16px "Montserrat", sans-serif;
  color: #666666;
  padding-right: 10px;
}

.subheader span {
  flex: 1 1;
  background-color: #00c7c7ff;
  color: #fff;
  font: 600 16px "Montserrat", sans-serif;
  padding: 8px 6px;
  text-align: center;
}
.ArdhaDashaDiv ul,
.PradhamaDashaDiv ul {
  border: 1px solid #e5e6e7;
}
.ArdhaDashaDiv li,
.PradhamaDashaDiv li {
  height: 36.8px;
}

.ArdhaDashaHeader {
  font: 400 20px "Montserrat", sans-serif;
  color: #666666;
  min-height: 53px;
}

.ArdhaImages {
  width: 20px;
}

.ArdhaPlanet {
  font: 600 16px "Montserrat", sans-serif !important;
  color: #999 !important;
}

.ArdhaDates {
  font: 500 16px "Poppins", sans-serif !important;
  color: #999 !important;
  float: right;
}

.border-gradient {
  border: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-radius: 50px;
}
.border-gradient-purple {
  border-radius: 10px;
  border-image-source: linear-gradient(
    90deg,
    rgba(126, 126, 133, 1) 0%,
    rgba(251, 251, 251, 1) 50%,
    rgba(205, 205, 205, 1) 100%
  );
}
.border-gradient-purple2 {
  border-image-source: linear-gradient(
    90deg,
    rgba(126, 126, 133, 1) 0%,
    rgba(251, 251, 251, 1) 50%,
    rgba(205, 205, 205, 1) 100%
  );
}

/* Dasha Css */

.HeaderDiv .basic-single-label {
  padding: 0px;
  text-align: left;
}

.drp-dasha:focus-visible {
  outline: none !important;
}
.dasha-system > div > div > div {
  font: 600 14px "Montserrat", sans-serif;
  color: #666666;
}

.dasha-system {
  width: 180px;
  height: 37px;
  display: block;
  font-size: 16px;
}
.drp-dasha > span {
  display: none !important;
}
.drp-dasha > span:hover {
  border-color: transparent !important;
}
.drp-dasha svg {
  margin-right: 10px;
}
.drp-mahadasha .react-date-picker,
.react-date-picker * {
  font: 600 16px "Montserrat", sans-serif;
  color: #666666;
}
.drp-mahadasha .react-date-picker__inputGroup__input {
  font: 600 16px "Montserrat", sans-serif;
  color: #666666 !important;
}
.drp-mahadasha .react-date-picker__wrapper {
  border-radius: 4px;
  width: 180px;
  height: 41px;
}
.mahadashaOf {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #666;
  display: inline-block;
}
.planet-name {
  font-family: "Montserrat", sans-serif;
  margin-top: 16px;
  display: inline-block;
  color: #ff6767ff;
}

.LordNames {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #666;
}
.ArdhaDashaDiv hr,
.PradhamaDashaDiv hr {
  background-color: rgba(0, 0, 0, 0.1);
}
.btn-prev,
.btn-next {
  margin-top: 20px !important;
  padding: 10px 25px !important;
  background: #008585ff !important;
  /* background: url("../../../assets/img/bg-btn.png") no-repeat !important;
  background-size: cover; */
  width: 180px;
  height: 40px;
}
.btn-next {
  float: right !important;
}
.btn-prev span,
.btn-next span {
  font: 600 14px "Montserrat", sans-serif !important;
  color: #fff;
  text-transform: capitalize;
  white-space: nowrap;
}
.ArdhaDashaDiv ul li .ArdhaPlanet,
.ArdhaDashaDiv ul li .ArdhaDates,
.PradhamaDashaDiv ul li .ArdhaPlanet,
.PradhamaDashaDiv ul li .ArdhaDates {
  flex: 1 1;
  text-align: center;
}
.react-datepicker {
  position: absolute !important;
  z-index: 99;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .navbar-custom .custom-control {
    width: 12% !important;
  }
  .MahaDasha-demo-2 {
    padding: 0 0rem !important;
  }
  .MahaDashaDates,
  .PradhamaDashaDates {
    padding: 10px 0;
    font-size: 14px;
  }
  .MahaDashaDates b,
  .PradhamaDashaDates b {
    font-size: 16px;
  }
  .ArdhaDashaDiv li:last-child > hr,
  .PradhamaDashaDiv li:last-child > hr {
    height: 0px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .navbar-custom .custom-control {
    width: 15% !important;
  }

  .MahaDashaDates,
  .PradhamaDashaDates {
    padding: 10px 0;
    font-size: 14px;
  }
  .MahaDashaDates b,
  .PradhamaDashaDates b {
    font-size: 16px;
  }
  .ArdhaDashaDiv li:last-child > hr,
  .PradhamaDashaDiv li:last-child > hr {
    height: 0px;
  }
  .ArdhaDashaHeader {
    min-height: auto;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
  .ArdhaTitleImages {
    margin-top: 0px !important;
  }
  .dasha-name,
  .CurrentDateHeader {
    font: 14px 700 "Montserrat", sans-serif !important;
    color: #666666;
    text-align: left;
  }
}
@media only screen and (max-width: 763px) {
  .navbar-custom .custom-control-label {
    padding-top: 20px;
    white-space: nowrap;
  }

  .dasha-name,
  .CurrentDateHeader {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #666666;
    float: none;
  }
  .drp-dasha {
    margin: 0 auto;
  }
  .HeaderDiv .basic-single-label {
    float: none !important;
  }
  .MahaDasha-demo-2 {
    padding: 0 20px !important;
  }
  .MahaDashaDates span {
    padding: 0px;
    font-size: 16px;
  }

  .ArdhaDashaDiv li,
  .PradhamaDashaDiv li {
    padding: 8px 5px;
  }
  .ArdhaDashaDiv h3 {
    font-weight: 600;
  }

  .ArdhaDashaHeader {
    min-height: auto;
  }
  .ArdhaTitleImages {
    margin-top: 50px;
  }
  .planet-name {
    font-size: 28px;
    white-space: nowrap;
  }
  .MahaDashaDates b,
  .PradhamaDashaDates b {
    font-size: 14px;
  }
  .dasha-name {
    display: block;
  }
  .CurrentDateHeader {
    float: none;
  }

}
@media only screen and (max-device-width: 480px) {
}
/* .MuiListItemText-root {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
} */
/* .MuiListItemText-root{
  margin-top: 5px !important;
  margin-bottom: 5px !important;
} */
.ArdhaDashaDiv li, .PradhamaDashaDiv li{
  height:40px !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;

}
