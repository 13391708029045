.primaryModel {
  width: 90%;
}
.ladda-button {
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  margin-left: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sideP {
  padding: 8px 20px;
  color: #6e768e;
  display: block;
  position: relative;
  transition: all 0.4s;
  font-size: 0.875rem;
  cursor: pointer;
  margin-bottom: 0;
}

.sideP:hover {
  padding: 8px 20px;
  display: block;
  position: relative;
  transition: all 0.4s;
  font-size: 0.875rem;
  cursor: pointer;
  margin-bottom: none;
  text-decoration: underline;
  color: #3283f6;
}

.sidebar_botom{
  padding-left: 40px;
  bottom: 35px;
  position:absolute;
}
