.btn-purple-moon {
    background: #4e54c8;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #8f94fb, #4e54c8);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #8f94fb, #4e54c8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #fff;
    border: 1px solid #eee;
}

.btn-purple-moon2 {
    background: #4e54c8;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #8f94fb, #4e54c8);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #8f94fb, #4e54c8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #fff;
    font-size: 20px;
    font-variant: normal;
}
.font-size-18{
    font-size: 20px;
}

.border-gradient {
    border: 1px solid;
    border-image-slice: 1;
    border-width: 1px;
    border-radius: 50px;
  }
  .border-gradient-purple {
    border-radius: 10px;
    border-image-source: linear-gradient(90deg, rgba(126,126,133,1) 0%, rgba(251,251,251,1) 50%, rgba(205,205,205,1) 100%);
  }
  .border-gradient-purple2 {
    border-image-source: linear-gradient(90deg, rgba(126,126,133,1) 0%, rgba(251,251,251,1) 50%, rgba(205,205,205,1) 100%);
  }
