.shodya {
  margin-top: 2.5%;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}

.astak {
  margin-top: 1.5%;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}

/********************************************************************/
/*** PANEL WARNING ***/
.with-nav-tabs.panel-warning .nav-tabs > a,
.with-nav-tabs.panel-warning .nav-tabs > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > a:focus {
  color: #fff;
}
.tab-content {
  padding: 0px;
}
/* .tab-outer-border {
  border: 1px solid #ff6e4a;
  border-top-color: transparent;
} */

.with-nav-tabs.panel-warning .nav-tabs > a,
.with-nav-tabs.panel-warning .nav-tabs > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > a:focus {
  color: #fff;
  background-color: #fff;
  border-color: #ee6565;
  border-bottom-color: transparent;
}
/* .with-nav-tabs.panel-warning .nav-tabs {
  border-color: #ee6565;
} */
.with-nav-tabs.panel-warning .nav-tabs > a {
  color: #ee6565;
  border-bottom-color: #ee6565;
}
.with-nav-tabs.panel-warning .nav-tabs > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > a:focus {
  background-color: #ee6565;
}
.with-nav-tabs.panel-warning .nav-tabs > .active > a,
.with-nav-tabs.panel-warning .nav-tabs > .active > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > .active > a:focus {
  color: #fff;
  background-color: #8a6d3b;
}
.with-nav-tabs.panel-warning .nav-tabs > .active {
  color: #fff;
  background-color: #ee6565;
}

.reset {
  margin-left: auto;
}

.planetOptions {
  margin-left: 30px;
  margin-top: 5px;
}

.typeOptions {
  margin-left: 30px;
  margin-top: 5px;
}

.header {
  margin-bottom: 0px;
  font-weight: bold;
}

.content-table-net {
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 1.1em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.content-table-net th,
.content-table-net td {
  padding: 10px 7px 10px 7px;
  margin: 0;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  text-align: center;
}

.content-table {
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 1.1em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.prasthar {
  width: 100%;
  margin: 40px 0;
}

.sarvashtak {
  width: 100%;
  margin: 50px 0;
}
.prasthar tbody tr:last-child > td img,
.prasthar tbody tr:nth-last-child(2) > td img {
  display: none;
}
.content-table thead tr,
.content-table-net thead tr {
  background-color: #008585ff;
  color: #ffffff;
}

.content-table th,
.content-table-net th {
  font-weight: bolder;
}

.content-table th,
.content-table td {
  padding: 0.4rem 0.4rem 0.4rem 0.4rem;
  margin: 0;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  text-align: center;
}

.content-table tbody tr,
.content-table-net tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:last-of-type,
.content-table-net tbody tr:last-of-type {
  border-bottom: 2px solid #008585ff;
}

.content-table tbody tr.active-row,
.content-table-net tbody tr.active-row {
  font-weight: bold;
  color: #008585ff;
}

.content-table tr:hover,
.content-table-net tr:hover {
  box-shadow: 0 13px 48px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #008585ff;
  /* -webkit-transform: scale(1.15); */
  color: #fff !important;
}

.typeButton {
  color: #00adadff;
  border-color: #00adadff;
}
.filled-button-type {
  background: #00adadff;
  color: #fff;
  border-color: #00adadff;
}
.filled-button-type:hover:not([disabled]),
.typeButton:hover:not([disabled]) {
  background: #00adadff;
  color: #fff;
  border-color: #00adadff;
}

.planetButton {
  color: #666666ff;
  border-color: #666666ff;
}
.filled-button-planet {
  background: #666666ff;
  color: #fff;
  border-color: #666666ff;
}
.filled-button-planet:hover,
.planetButton:hover {
  background: #666666ff;
  color: #fff;
  border-color: #666666ff;
}

.total-row {
  font-weight: bold;
}

.header-table {
  font-size: 1.3em;
}

.AstakImages {
  width: 15px;
}
/********************************************************************/
.planet-images {
  width: 20px;
}
.prasthar tbody tr:last-child > td img,
.prasthar tbody tr:nth-last-child(2) > td img {
  display: none;
}
.astak tbody tr:last-child > td img,
.sarvashtak tbody tr:last-child > td img {
  display: none;
}
.PlanetImages {
  height: 40px;
  padding-right: 10px;
}

.PlanetsName {
  font: 600 30px "Montserrat", sans-serif;
  color: #666666;
}
.astak table{
  height: 445px;
}