@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap");

.bhav {
  width: 100%;
  position: relative;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}

.bhav .table-striped > thead > tr > th,
.bhav .table-striped > tbody > tr > td {
  padding: 8px !important;
}

.bhav .table-striped > tbody > tr > td {
  font-size: 14px;
}
#snapshot #d3graph {
  position: relative;
  padding: 1rem;
  border: 1px solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}

.BhavBalaButtons {
  width: 50%;
  background: #4aaead !important;
  height: 41px;
  color: #fff;
  font-size: 16px;
  border: 0px solid #fff;
  font-family: "Lucida Bright";
  white-space: nowrap;
}
.BhavBalaButtons-not {
  width: 50%;
  background: url("../../../assets/img/bg-btn.png") no-repeat !important;
  background-size: cover;
  border: thin solid #b2b0b0 !important;
  height: 41px;
  color: #8b8b8b;
  font-size: 16px;
  font-family: "Lucida Bright";
  white-space: nowrap;
}
.header-title .BhavBalaButtons-not:hover,
.header-title .BhavBalaButtons-noty:focus,
.header-title .BhavBalaButtons-not:active {
  color: #4aaead !important;
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
  .bhav .table-responsive-xs {
    overflow-x: auto !important;
  }
  .bhav .js-plotly-plot .plotly .main-svg {
    width: 100% !important;
  }
}

@media only screen and (max-width: 763px) {
  .bhav .table-responsive-xs {
    overflow-x: auto !important;
  }
}

.content_table_bhav {
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 1.15em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-left: auto;
  width: 100%;
  margin-right: auto;
}

.content_table_bhav thead tr {
  background-color: #008585ff;
  color: #ffffff;
}

.content_table_bhav th {
  font-weight: bolder;
  text-align: center;
}

.content_table_bhav th,
.content_table_bhav td {
  padding: 0.7rem 0.4rem 0.7rem 0.4rem;
  margin: 0;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  font-family: "Lucida Bright";
}

.content_table_bhav tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content_table_bhav tbody tr:last-of-type {
  border-bottom: 2px solid #008585ff;
}

.content_table_bhav tbody tr.active-row {
  font-weight: bold;
  color: #008585ff;
}

.content_table_bhav tr:hover,
.content_table_bhav tr:hover {
  box-shadow: 0 13px 48px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #008585ff;
  /* -webkit-transform: scale(1.15); */
  color: #fff;
}

.bhav-chart-div {
  max-height: 550px;
}

.left-align-bhav {
  text-align: left;
}

.center-align-bhav {
  text-align: center;
}

.right-align-bhav {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.main-div {
  min-height: 650px;
  position: relative;
}

.main-title {
  font-size: 1.875rem;
  color: #666666;
  font-family: "Lucida Bright";
  font-weight: bold;
}
