.date_sidetable {
  background-color: #6148a9;
  font-size: 20px;
  padding: 4px;
}

.table-bordered2 {
  font-size: 16px;
  color: white;
  border-radius: 10px;
  width: 50%;
}

.table-bordered2 > thead {
  background-color: #6148a9;
  color: #fff;
}
.table-bordered2 > tr:nth-child(even) > td,
.table-bordered2 > tr:nth-child(even) > th {
  background-color: #0a0d22;
  color: #fff;
}

.table-bordered2 > tr:nth-child(odd) > td,
.table-bordered2 > tr:nth-child(odd) > th {
  background-color: #141938;
  color: #fff;
}
