@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap");

.shad {
  width: 100%;
  position: relative;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}
.section-header .title {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #8b8b8b;
}
.section-header label {
  font-family: "Montserrat", sans-serif;
  font-size: 16px !important;
  font-weight: 400;
  color: #8b8b8b;
}

.shad .table-striped > thead > tr > th,
.shad .table-striped > tbody > tr > td {
  padding: 8px !important;
  font-family: "Montserrat", sans-serif;
}

.shad .table-striped > tbody > tr > td {
  font-size: 14px;
}
#d3graph {
  position: relative;
  padding: 1rem;
  border: 1px solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}
.ShadBalaButtons {
  background: #4aaead !important;
  height: 41px;
  color: #fff;
  font-size: 14px;
  padding: 1px 1px 1px 1px;
  border: 0px solid #fff;  
  font-family: "Lucida Bright", sans-serif;
}
.ShadBalaButtons-not {
  background: url("../../../assets/img/bg-btn.png") no-repeat !important;
  background-size: cover;
  border: thin solid #b2b0b0 !important;
  height: 41px;
  color: #8b8b8b;
  font-size: 16px;
  padding: 1px 1px 1px 1px;
  font-family: "Lucida Bright", sans-serif;
}
.header-title .ShadBalaButtons-not:hover,
.header-title .ShadBalaButtons-not:focus,
.header-title .ShadBalaButtons-not:active {
  color: #4aaead !important;
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
  .shad .table-responsive-xs {
    overflow-x: auto;
  }
  .shad .js-plotly-plot .plotly .main-svg {
    width: 100% !important;
  }
}
@media only screen and (max-width: 763px) {
  .shad .table-responsive-xs {
    overflow-x: auto !important;
  }
}

.content_table_shad {
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 1.15em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-left: auto;
  margin-right: auto;
}

.content_table_shad thead tr {
  background-color: #008585ff;
  color: #ffffff;
}

.content_table_shad th {
  font-weight: bolder;
  text-align: center;
}

.content_table_shad th,
.content_table_shad td {
  padding: 0.7rem 0.4rem 0.7rem 0.4rem;
  margin: 0;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  font-family: "Lucida Bright";
}

.content_table_shad tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content_table_shad tbody tr:last-of-type {
  border-bottom: 2px solid #008585ff;
}

.content_table_shad tbody tr.active-row {
  font-weight: bold;
  color: #008585ff;
}

.content_table_shad tr:hover {
  box-shadow: 0 13px 48px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #008585ff;
  /* -webkit-transform: scale(1.15); */
  color: #fff !important;
}

.bold {
  font-weight: bold;
}

.shad-chart-div {
  max-height: 550px;
}
.left-align-shad {
  text-align: left;
}

.right-align-shad {
  text-align: right;
}
