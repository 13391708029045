.card{
    margin:24px;
}

.graph{
    margin: 5px;
    padding: 10px;;
}

.checkbox-input{
    padding: 5px;
    margin: 2px;
    vertical-align: middle;
}

.checkbox-label{
margin-right: 10px;
vertical-align: text-top;
}