.y-tick {
  stroke: grey;
  fill: none;
  stroke-width: 1px;
}

.bound {
  color: grey;
}

.axis g.tick line {
  stroke: #777;
  fill: none;
  stroke-width: 1px;
}

.line-separator,
.x-axis {
  stroke: #777;
  fill: none;
  stroke-width: 1px;
}

.drop-line:last-child .line-separator {
  display: none;
}
* {
  margin: 0;
  padding: 0;
}

a {
  color: #ff6e00;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1 {
  text-align: center;
  margin: 1.5rem auto 1rem;
  font-family: "Josefin Sans", Serif;
  font-weight: normal;
  font-size: 3rem;
  color: #aaa;
}

p {
  line-height: 1.5;
}

.infos {
  text-align: center;
  margin-bottom: 1rem;
}

#zoomStart,
#zoomEnd {
  display: inline-block;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

#zoomStart {
  text-align: "right";
}

#zoomEnd {
  text-align: "left";
}

.light {
  color: #aaa;
}

.drop {
  cursor: pointer;
}

footer p {
  text-align: center;
  color: #888;
  font-size: 0.8rem;
}

.tooltip {
  position: absolute;
  background: #fff;
  border: 3px solid #e7e7e7;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  width: 20rem;
  line-height: 1.4rem;
}

.tooltip::before {
  content: "";
  display: block;
  position: absolute;
  top: -0.65rem;
  width: 1rem;
  height: 1rem;
  background: #fff;
  border: 3px solid #e7e7e7;
  border-width: 3px 0 0 3px;
  transform: rotate(45deg);
  z-index: 1;
}

.tooltip.left::before {
  left: 1.65rem;
}

.tooltip.right::before {
  right: 1.65rem;
}

.tooltip .commit {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tooltip .avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  flex: 0 0 5rem;
}

.tooltip .content {
  flex: 1 0 0;
}

.tooltip h3 {
  font-size: 1rem;
}

.tooltip p {
  font-size: 0.9rem;
  color: #777;
}

.basic-single-label {
  padding-left: 3em;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  margin-left: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
