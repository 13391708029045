/*input[type=text]:disabled {*/
/*    background: #dddddd;*/
/*}*/
/*input[type=search]:disabled {*/
/*    background: #dddddd;*/
/*}*/
/*.celebManagement-toggle-input-ability:disabled{*/
/*    background: #dddddd;*/
/*}*/

#textDOB:disabled{
    background: #dddddd;
}
/*input:disabled {*/
/*    background: #dddddd;*/
/*}*/

.eventWrapper {
    margin-top: 70px;
    margin-left: 240px;
    padding: 10px;
    font-size: 16px;
    color: #666;
}
/*.eventWrapper:disabled{*/
/*    background: #dddddd;*/
/*}*/

.eventWrapper .card-body {
    border: 1px solid #ced4da;
    margin: 2rem;
}

.btn-theme {
    /*width: 90px;*/
    /*height: 38px;*/
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
}

.btn-update {
    width: 90px;
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 500;
}

.btn-delete {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 500;
}

.btn-upload {
    color: #fff;
    background-color: #0979bf;
    border-color: #0979bf;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 500;
}

.btn-link {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 500;
}

.btn-delete {
    width: 90px;
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 500;
}

.btn-transparent {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #dee2e6 !important;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 500;
}

.btn-theme:hover,
.btn-update:hover,
.btn-delete:hover,
.btn-upload:hover {
    color: rgba(255, 255, 255, 0.8);
}

.eventWrapper input {
    border-radius: 0px;
}

.eventWrapper select {
    background-color: hsl(0, 0%, 100%);
    border-color: rgba(118, 118, 118, 0.5);
    border-radius: 0px;
    border-style: solid;
    border-width: 1px;
    width: 100%;
    min-height: 38px;
}

.searchData ul {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
}

.searchData ul>li {
    display: block;
}

.img-photo {
    border: 2px solid gray;
}

.img-photo {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
}

.img-photo:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.inline-list {
    display: inline;
}

.drp-label {
    font: 400 12px sans-serif;
    background: hsl(0, 0%, 90%);
    color: hsl(0, 0%, 20%);
    padding: 3px 5px;
    margin-right: 5px;
}

.alphabetSearch li {
    cursor: pointer;
}
.autocompleteWrapper{
    position: relative;
    z-index: 9;
}
.suggestionWrapper{
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 99;
}
.suggestionWrapper li:first(:last-of-type) {
    border-bottom: 1px solid silver;
    padding: 5px;
}
.autocomplete:hover ul{
   display: none;
}
.autocomplete, .suggestion{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.9rem + 2px);
    padding: 0.5rem 0.9rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.suggestion:hover {
    background-color: rgb(245, 244, 244);
}
.primary-birth-details{
    position: relative;
    z-index: 9;
}
.alternate-birth-details{
    position: relative;
    z-index: 0;
}