

.form-error {
  font-size: 0.875rem;
  color: red;
}

.DateInput_input {
	font-size: 15px;
	padding: 5px 10px;
}

.sub-header {
	margin-bottom: 5px;
}



.modal-content{
	border-radius: 10px;
	padding-left: 50px;

}
.header_title{
	font-size: 16px;
	font-weight: 800;
	margin-top: -30px;
	margin-bottom: 30px;
	z-index: -10;
	
}
.SingleDatePicker{
	margin-right: 10px;
}

.yearInput-input{
	font-size: 15px;
	padding: 5px 10px;
	border: solid lightgray;
    border-radius: 4px;	
	width:25%;
}

.planetSelector{
	padding: 5px 10px;
	border: solid lightgray;
    border-radius: 4px;		
}