.chartname_title {
  font-size: 20px;
  margin-left: 2%;
  color: #da4433;
}
.rashiClass {
  fill: silver;
  font-size: 15px;
}

.planetClass1, .planetClass2, .planetClass3, .planetClass4, .planetClass5, .planetClass6, .planetClass7, .planetClass8 {
  fill: grey;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
}
 /* .planetClass1 {
  fill: pink;
  font-size: 17.5px;
  padding: 10px;
  font-weight: bold;
} */

/*.planetClass2 {
  fill: grey;
  font-size: 17.5px;
  padding: 10px;
  font-weight: bold;
}

.planetClass3 {
  fill: grey;
  font-size: 17.5px;
  padding: 10px;
  font-weight: bold;
}

.planetClass4 {
  fill: grey;
  font-size: 17.5px;
  padding: 10px;
  font-weight: bold;
}

.planetClass5 {
  fill: grey;
  font-size: 17.5px;
  padding: 10px;
  font-weight: bold;
} */

div.tooltip {
  position: absolute;
  text-align: center;
  pointer-events: none;
}

.side-table {
  border-collapse: collapse;
  font-size: 0.93em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.side-table thead tr {
  background-color: #009879;
  color: #ffffff;
}

.side-table th {
  font-weight: bolder;
}
.side-table td {
  color: "grey";
}
.side-table th,
.side-table td {
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  text-align: center;
}

/********************************************************************/
/*** PANEL WARNING ***/
.with-nav-tabs.panel-warning .nav-tabs > a,
.with-nav-tabs.panel-warning .nav-tabs > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > a:focus {
  color: #fff;
}
.tab-content {
  padding: 0px;
}
/* .tab-outer-border {
  border: 1px solid #ff6e4a;
  border-top-color: transparent;
} */

.with-nav-tabs.panel-warning .nav-tabs > a,
.with-nav-tabs.panel-warning .nav-tabs > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > a:focus {
  color: #fff;
  background-color: #fff;
  border-color: #ee6565;
  border-bottom-color: transparent;
}
/* .with-nav-tabs.panel-warning .nav-tabs {
  border-color: #ee6565;
} */
.with-nav-tabs.panel-warning .nav-tabs > a {
  color: #ee6565;
  border-bottom-color: #ee6565;
}
.with-nav-tabs.panel-warning .nav-tabs > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > a:focus {
  background-color: #ee6565;
}
.with-nav-tabs.panel-warning .nav-tabs > .active > a,
.with-nav-tabs.panel-warning .nav-tabs > .active > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > .active > a:focus {
  color: #fff;
  background-color: #8a6d3b;
}
.with-nav-tabs.panel-warning .nav-tabs > .active {
  color: #fff;
  background-color: #ee6565;
}

.reset {
  margin-left: auto;
}
/********************************************************************/
