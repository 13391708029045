.section-header .title {
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #b2b0b0;
}
.section-header label {
  font-family: "Montserrat", sans-serif;
  font-size: 16px !important;
  font-weight: 400;
  color: #b2b0b0;
}
.Planet-positions {
  position: relative;
  background-color: #fff;
}
.planet-positions .table-bordered {
  border: 1px solid #ccc;
}
.planet-positions .table thead th {
  border-bottom: 1px solid #ccc;
}

.planet-positions .table-striped > thead > tr > th,
.planet-positions .table-striped > tbody > tr > td {
  padding: 8px !important;
  font-family: "Montserrat", sans-serif;
}

.planet-positions .table-striped > tbody > tr:nth-child(even) > td,
.planet-positions .table-striped > tbody > tr:nth-child(even) > th {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.reset {
  margin-left: auto;
}

.content-table-pp {
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 1.15em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.content-table-pp thead tr {
  background-color: #008585ff;
  color: #ffffff;
}

.content-table-pp th {
  font-weight: bolder;
  text-align: center;
}

.content-table-pp th,
.content-table-pp td {
  padding: 0.7rem 0.4rem 0.7rem 0.4rem;
  margin: 0;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
}

.content-table-pp tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table-pp tbody tr:last-of-type {
  border-bottom: 2px solid #008585ff;
}

.content-table-pp tbody tr.active-row {
  font-weight: bold;
  color: #008585ff;
}

.content-table-pp tr:hover {
  box-shadow: 0 13px 48px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #008585ff;
  /* -webkit-transform: scale(1.15); */
  color: #fff !important;
}

.PpImages {
  width: 20px;
  margin-right: 30px;
}

.zoom_container {
  height: 700px;
  overflow: hidden;
}

.rotate {
  animation: rotation 50s infinite linear;
  margin-right: -102%;
  margin-top: -41%;
}

.rotate svg {
  width: 94%;
  height: 99%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
  cursor: pointer;
}

.svg_img_sudharshan svg {
  width: 600px;
  height: 600px;
  max-height: 700px;
}
.svg_img svg {
  width: 600px;
  height: 600px;
  max-height: 700px;
}
#malefic_benefic tbody tr:last-child td {
  display: none;
}

.myClass {
  display: flex;
  flex-wrap: wrap;
}
#malefic_benefic tbody tr td span svg{
  display:none;
}

#malefic_benefic tbody tr td span:first-child svg{
  display:inline-block;
}
