.dpFlex {
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
}

#card-body-holistic {
  box-shadow: 0 0 0 10px hsl(0, 0%, 80%), 0 0 0 15px hsl(0, 0%, 90%);
}

u {
  text-decoration: none;
}

.bgimg {
  box-shadow: 0 0 0 10px hsl(0, 0%, 80%), 0 0 0 15px hsl(0, 0%, 90%);
}

.dpCard a,
.dpPanchangWrapper {
  font-size: 1.4rem;
}

.dpTableCardTitle {
  width: 100%;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: #333;
  text-underline-offset: 6px;
}

.dpTableCardTitle {
  padding: 6px;
  min-width: 580px;
  font-size: 1.1rem;
  text-align: center;
}

.dpCardRow {
  position: relative;
  padding-left: 28px;
}
.TableIcon {
  margin-left: 5px;
  margin-right: 5px;
  width: 30px;
}
.MapIcon {
  width: 30px;
  margin-bottom: 5%;
  margin-left: 1%;
  cursor: pointer;
}
.dpRashiCell .dpElementImage {
  left: 0;
  top: 4px;
  width: 16px;
}
.dpStrengthGroup + .dpStrengthGroup {
  padding-top: 6px;
}
.dpTableElementImage {
  top: 6px;
  left: 4px;
  width: 16px;
  position: absolute;
}
.dpPanchangMuhurtaCell .dpTableElementImage {
  width: 13px;
  position: static;
  vertical-align: middle;
  margin-right: 8px;
}

.dpTableCardWrapper {
  width: 100%;
  font-size: 1.3rem;
  overflow: auto;
  height: 70vh;
}
.dpTableCard {
  width: 100%;
  min-width: 580px;
  padding: 6px;
  display: table;
  border-collapse: collapse;
}
.dpTableRow {
  width: 100%;
  padding: 10px;
  display: table-row;
}
.dpTableCell {
  width: 50%;
  padding: 6px;
  padding-bottom: 200px;
  position: relative;
  display: table-cell;
}
.dpPanchangMuhurtaCard .dpTableCell {
  padding: 2px 6px;
}
.dpPanchangMuhurtaCell {
  padding: 2px;
  position: relative;
}

.sectionheader {
  font-size: 1.3rem;
  font-weight: 600;
}

.dpTableKey,
.dpTableValue {
  line-height: 1.3;
  font-size: 1.1rem;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}
.dpTableKey {
  width: 17%;
  font-weight: 550;
  text-align: right;
}
.dpTableValue {
  width: 33%;
  font-weight: 500;
  padding-left: 44px;
}
.dpPanchangMuhurtaCard .dpTableKey {
  display: none;
}
.dpRashiCell {
  width: 50%;
  position: relative;
  display: inline-block;
  padding: 0 2px 0 20px;
}
.dpGroupCard .dpTableCell {
  width: 100%;
  display: block;
}
.dpNoteText {
  font-weight: normal;
}
@media only screen and (max-width: 330px) {
  .dpFullCard {
    text-align: left;
  }
  .dpInlineCardRow {
    min-width: 100%;
  }
}
@media only screen and (min-width: 500px) {
  .dpGroupCard {
    padding: 6px;
  }
  .dpCard {
    width: 50%;
    flex-basis: 180px;
    -webkit-flex-basis: 180px;
  }
  .dpCardRow {
    padding-left: 32px;
  }
  .dpFullCard {
    flex-basis: 45%;
    -webkit-flex-basis: 45%;
  }
  .dpInlineCardRow {
    min-width: 33%;
    padding-left: 34px;
  }
  .dpGroupCardTitle {
    padding-left: 36px;
  }
  .dpElementImage {
    left: 6px;
  }
  .dpRashiCell .dpElementImage {
    left: 0;
  }
}
@media only screen and (min-width: 1124px) {
  .dpTableCardTitle {
    padding: 15px;
    color: #454545;
    font-size: 1.3rem;
    font-weight: 800;
    text-decoration-color: #333;
    text-underline-offset: 6px;
  }
  .dpGroupCardTitle {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 2px;
  }
  .dpCard {
    margin: 2px;
    min-width: 0;
    flex-basis: 220px;
    -webkit-flex-basis: 220px;
  }
  .dpGroupCard .dpFlexEqual .dpCard {
    width: 100%;
    border: 2px solid green !important;
  }
  .dpPanelWrapper {
    padding-left: 50px !important;
  }
  .dpRashiCell {
    width: 33%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dpGroupCard .dpRashiCell {
    width: 20%;
  }
  .dpNoteText {
    max-width: 100%;
  }
}
.dpTableCell,
.dpPanchangMuhurtaCard {
  background: white;
}
.dpPanchangWrapper {
  background: white;
}
.dpTableCardWrapper {
  font-weight: 600;
  background: white;
}

.dpTitle,
.dpTableKey,
.dpTitle a,
.dpTableKey a {
  color: rgb(3, 66, 141);
}
.dpTitle a:hover,
.dpTableKey a:hover {
  color: #581e00;
}
.dpTableValue {
  color: #404040;
}

.dpTableCell a:hover {
  color: #a00;
}
