.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;

  z-index: 1000;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}

.suggestions li {
  padding: 5px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
  display: block;
}

.suggestion-active,
.suggestions li:hover {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
  cursor: pointer;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid silver;
  padding: 5px;
}
