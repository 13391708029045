.template-input{
    padding: 10px;
    width: 90%;
}

.button-s{
    padding: 5px;
}

.template-validator-message{
    color: red;
    font-size: small;
}

.template-modal-rows{
    padding: 5px;
}
.select-dropdown{
    padding:5px;
}

.build-query-heading{
    text-align: center;
    font-size: large;
    color: black;
    font-weight: bold;
    padding: 10px;
}

.category-heading{float:right;font-weight:bold}

.template-search-row{
    padding-top: 2%;
    padding-bottom: 2%;
    /* padding-left: 0%; */
}

.build-query-div{
    padding: 12px;
}

.instructions-wrapper{
        padding-left: 5%;
        padding-right: 5%;
        text-align: justify;
}

.match-count-span{
    height: 100%;
    vertical-align: sub;
}




#accordion .panel{
    border: none;
    border-radius: 0;
    box-shadow: none;
   /* margin: 0 30px 10px 30px;*/
    overflow: hidden;
    position: relative;
}
#accordion .panel-heading{
    padding: 0;
    border: none;
    border-radius: 0;
    position: relative;
}
#accordion .panel-title a{
    display: block;
    padding: 15px 20px;
    margin: 0;
    background: #fe7725;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #fff;
    border-radius: 0;
    position: relative;
}
#accordion .panel-title a.collapsed{ background: #1c2336;}
#accordion .panel-title a:before,
#accordion .panel-title a.collapsed:before{
    content: "\f068";
    width: 30px;
    height: 30px;
    line-height: 25px;
    border-radius: 50%;
    background: #fe7725;
    font-size: 14px;
    font-weight: normal;
    color: #fff;
    text-align: center;
    border: 3px solid #fff;
    position: absolute;
    top: 10px;
    right: 14px;
}
#accordion .panel-title a.collapsed:before{
    content: "\f067";
    background: #ababab;
    border: 4px solid #626262;
}
#accordion .panel-title a:after,
#accordion .panel-title a.collapsed:after{
    content: "";
    width: 17px;
    height: 7px;
    background: #fff;
    position: absolute;
    top: 22px;
    right: 0;
}
#accordion .panel-title a.collapsed:after{
    width: 19px;
    background: #ababab;
}
#accordion .panel-body{
    border-left: 3px solid #fe7725;
    border-top: none;
    background: #fff;
    /*font-size: 15px;*/
    color: #1c2336;
    line-height: 27px;
    position: relative;
}
#accordion .panel-body:before{
    content: "";
    height: 3px;
    width: 50%;
    background: #fe7725;
    position: absolute;
    bottom: 0;
    left: 0;
}
#accordion .panel-body p{
  padding: 10px;
}