.natalChart-wrapper {
  width: 100%;
  position: relative;
}
.natalChart-wrapper .chartname_title {
  font-size: 20px;
  color: #da4433;
  position: absolute;
  left: 8%;
  top: 6%;
}
.natalChart-wrapper .chart-box {
  padding: 20px;
  margin: 0 auto;
  text-align: center;
}
.natalChart-wrapper .chart-box img {
  width: 481px;
  height: 481px;
}
.natalChart-wrapper .content-table {
  width: 95%;
}
