.divisionalChartDiv {
  position: relative;
  background: #fff;
}
.activeClass {
  position: relative;
}
.activeClass ul > li > a {
  background-color: white;
  color: #ee6565 !important;
}
.activeClass ul > li > a:hover {
  background-color: pink;
}
.activeClass .nav-item {
  background-color: #fff;
  border-color: #ee6565;
  border-bottom-color: transparent;
}
.activeClass .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #ee6565 !important;
  color: #fff !important;
}
.activeClass .nav-item.show .nav-link,
.nav-tabs .nav-link:hover,
.activeClass .nav-item.show .nav-link,
.nav-tabs .nav-link:focus {
  color: #ee6565;
  border-color: #ee6565;
  border-bottom-color: transparent;
}
.activeClass ul > li.disabled > a {
  background-color: lightgray;
  color: darkgray;
}

.content-table-div {
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 1.05em;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.content-table-div thead tr {
  background-color: #008585ff;
  color: #ffffff;
}

.content-table-div th {
  font-weight: bolder;
  padding: 0.7rem 0.2rem 0.7em 0.2rem;
}
.content-table-div td {
  padding: 0.4rem 0.2rem 0.4rem 0.2rem;
}

.content-table-div th,
.content-table-div td {
  margin: 0;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  line-height: 15px;
}

.content-table-div tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table-div tbody tr:last-of-type {
  border-bottom: 2px solid #008585ff;
}

.content-table-div tbody tr.active-row {
  font-weight: bold;
  color: #008585ff;
}

.content-table-div tr:hover {
  box-shadow: 0 13px 48px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #008585ff;
  /* -webkit-transform: scale(1.15); */
  color: #fff !important;
}

.imageDiv {
  position: absolute;
  top: 42%;
  left: 46%;
  transform: translate(-50%, -50%);
  /* margin-left: 41.75%;
  margin-top: 15%; */
}

.dot-red {
  height: 20px;
  width: 20px;
  background-color: #ed3833;
  border-radius: 50%;
  display: inline-block;
}

.dot-green {
  height: 20px;
  width: 20px;
  background-color: #6aa84f;
  border-radius: 50%;
  display: inline-block;
}

.title {
  font-weight: bolder;
}

.bold-row {
  font-weight: bold;
}

.image-center {
  margin-left: 5px;
  margin-right: 5px;
}
