@import url("https://fonts.googleapis.com/css2?family=Lucida Bright:ital,wght@0,200;0,300;0,400;0,500;1,400&display=swap");
.content-page {
  padding: 0 !important;
  margin-top: 72px !important;
}
.cview-wrapper {
  position: relative;
  width: 100%;
  color: #212529;
  border-radius: 8px;
  z-index: 0;
}

select {
  font-family: "Lucida Bright", sans-serif;
}
.section-title {
  position: fixed;
  width: calc(100% - 70px);
  height: 68px;
  background: #fff;
  border-bottom: 1px solid #00b7eb;
  box-shadow: 8px 3px 12px 5px rgb(0 0 0 / 8%);
  padding: 0;
  margin-top: 0px;
  z-index: 99;
}
.info h4 {
  font-family: "Lucida Bright", sans-serif;
  font-weight: 500;
  color: #666;
  margin-top: 10px;
  margin-bottom: 0px;
}
.info p b,
.CurrentDateHeader b {
  font-family: "Lucida Bright", sans-serif;
  font-weight: 400;
  color: #ff6e4a;
}
.dasha-details {
  position: relative;
}
.dasha-name {
  font-size: 13px;
}
.dasha-details h4 {
  font-family: "Lucida Bright", sans-serif;
  font-weight: 500;
  color: #666;
  margin-bottom: 0px;
}
.dasha-details p {
  font-family: "Lucida Bright", sans-serif;
  color: #666;
  margin-bottom: 0px;
}
.dasha-details b {
  font-family: "Lucida Bright", sans-serif;
  color: #ff6e4a;
  font-weight: 400;
}
.dasha-date b {
  font-family: "Lucida Bright", sans-serif;
  width: 50%;
  display: inline-block;
  color: #4441fa;
}
.dasha-date p {
  font-family: "Lucida Bright", sans-serif;
  font-weight: 500;
  color: #666;
  margin-bottom: 0px;
}
.dasha-details p span,
.dasha-date p span {
  font-family: "Lucida Bright", sans-serif;
  white-space: nowrap;
}
.dashaPlanet{
  width: 50px;
  display: inline-block;
}
.CurrentDateHeader span {
  font-weight: 400;
  color: #666;
}
.info p {
  margin-top: 12px;
  font-family: "Lucida Bright", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-left: 5px;
}
.info-card span {
  font-family: "Lucida Bright", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #666;
}
.drp-currentDasha > div {
  width: 180px;
}
.drp-currentDasha > div > div {
  height: 28px;
  min-height: 28px;
}

.drp-currentDasha > div > div > div {
  margin-top: -6px;
}
.charts-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 6px;

  grid-template-columns: 1fr;
  padding: 10px;
  margin-top: 68px;
  z-index: 9;
}
.chartbox {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  padding: 0px;
  margin-bottom: 0px;
  padding-right: 2px;
}
.chartbox h4 {  
  flex: 1 1;
  white-space: nowrap;
  margin-top: 0px;
  font: 400 20px "Lucida Bright", sans-serif;
  color: #ff6e4a;
  text-align: left;
}
.chartbox img {
  width: 100%;
  height: auto;
}
.divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 10px;
  width: 99%;
  margin: 0 0.7%;
  text-align: center;
}
.chartbox .d-flex {
  height: 30px;
}

.chartbox .drp {
  width: 200px;
  padding-bottom: 3px;
  height: 28px;
  padding-top: 3px;
}

.breadcrumb-item a {
  font: 400 16px "Lucida Bright", sans-serif;
}
.breadcrumb {
  padding-top: 0px;
  margin-top: -4px;
  padding-left: 10px;
}
.cview-wrapper select {
  width: 180px;
  padding-bottom: 3px;
  height: 28px;
  padding-top: 3px;
}
.dasha-calendar .react-date-picker {
  color: #333;
}
.dasha-calendar .react-date-picker__inputGroup__input,
.react-date-picker * {
  color: #333 !important;
  font: 600 13px "Lucida Bright", sans-serif;
}
.dasha-calendar .react-date-picker__wrapper {
  width: 180px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: 28px;
}
.dashaDate {
  font: 16px "Lucida Bright";
}
.ico-calendar {
  color: #ff6e4a;
  margin-left: 5px;
}

.cview-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  font-size: 12px;
  padding: 0px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.cview-table thead tr {
  background-color: #008585ff;
  color: #ffffff;
}

.cview-table th {
  font-weight: bold;
  text-align: center;
}

.cview-table td,
.cview-table th {
  padding: 0.8rem 0rem 0.8rem 0rem;
  margin: 0;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  text-align: center;
}

.cview-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.cview-table tbody tr:last-of-type {
  border-bottom: 2px solid #008585ff;
}

.cview-table tbody tr.active-row {
  font-weight: bold;
  color: #008585ff;
}

.cview-table tr:hover {
  box-shadow: 0 13px 48px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #008585ff;
  /* -webkit-transform: scale(1.15); */
  color: #fff !important;
}
.tabName {
  font-family: "Lucida Bright";
  font-size: 15px;
  font-weight: 500;
  height: 42px;
  padding: 10px 0;
  box-sizing: border-box;
}
.cview-table-pp {
  width: 60%;
  margin: 0 auto;
  font-weight: bold;
  border: none;
  table-layout: fixed;
  font-size: 16px;
  padding: 0px;
}

.cview-table-pp td,
.cview-table-pp th {
  padding: 0.5rem 0.3rem 0;
  border: none;
  font-family: "Lucida Bright";
  font-size: 16px;
  white-space: nowrap;
}

.left-align-cview {
  text-align: left;
  color: #008585ff;
  word-break: break-all;
}

.right-align-cview {
  text-align: right;
  color: #666666;
  word-break: break-all;
}

.cview-button {
  padding: 5px 3px 5px 3px;
  font-size: 0.85em;
  color: #666666ff;
  border-color: #666666ff;
}

.cview-button-filled {
  padding: 5px 3px 5px 3px;
  font-size: 0.85em;
  background: #666666ff;
  color: #fff;
}


.btnGrpTabs {
  display: flex;
  flex-wrap: wrap;
}
.heading {
  color: #838181;
}
.example-custom-input {
  border-color: white;
  background: #fff;
  border-style: inherit !important;
}
.planetImages {
  width: 28px !important;
}
.nakshtraImages {
  height: 25px !important;
  width: auto !important;
}
.zodiacImages {
  width: 20px !important;
}
.lordImages {
  width: 25px !important;
}
.cview-table-pp {
  color: #666;
}
.cview-table-pp .infoVal {
  color: #008585;
}
.cview-table-pp > div {
  font-family: "Lucida Bright", sans-serif;
  font-size: 13px;
  flex: 1 1 0;
  font-weight: 500;
}
.tabName {
  height: 40px;
  font-family: "Lucida Bright", sans-serif;
  font-size: 20px;
  color: #666;
  font-weight: 600;
}
@media screen and (min-width: 1600px) {
  .btn-group > .btn {
    flex: 1 1 auto !important;
  }
}
@media only screen and (max-width: 1600px) {
  .chartbox {
    width: 100%;
    flex: none;
  }
  .btn-group > .btn {
    flex: 1 1 20% !important;
  }
}
@media only screen and (max-width: 763px) {
  .chartbox {
    width: 100%;
    flex: none;
  }
}

.PlanetImagesCview {
  height: 20px;
  padding-right: 10px;
}

.PlanetsNameCview {
  font: 600 30px;
  color: #666666;
}
