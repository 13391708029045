#myDiv {
  min-width: 800px;
  min-height: 600px;
  margin-bottom: 105px;
}
.g-xtitle[transform=translate]{
  transform: translate(0,30) !important;
}
#myDiv_NAKS {
  min-width: 900px;
  min-height: 700px;
}
#myDiv_combined {
  min-width: 900px;
  min-height: 700px;
}

.ShadBalaButtons {
  font-size: 0.75em;
}
.highlighted {
  box-shadow: 0 0 0 0.2rem rgb(254 216 84 / 50%);
}

.btn-primary {
  margin-right: 5px;
}

