
.YogaAnalizerWrapper{
    background-color: #fff;
}
.fbasis{
    flex-basis: 550px;
}
 .drpSelect select{
    width: 80%;
    min-height: 38px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid hsla(0,0%,46.3%,.5);
    margin-bottom: 15px;
}
.drpAusc select{
    color: #fff;
    background-color: #008585;
}
.entityClass{
    font-size: 20px !important;
}
.outlineBorder.MuiFormControl-root{
    position: relative;
    width: 88%;
    border:1px solid #ccc;
    border-radius: 3px;
    margin: 0 20px;
}
.outlineBorder textarea{
    border:0px
}
.outlineBorder label{
    border: 1px solid #ccc;
    background: #fff;
    padding: 6px;
    position: absolute;
    z-index: 9;
    top: -35px;
}
.sanskritShloka_img{
    background: url("../../../assets/img/bg_paper.png") no-repeat;
    padding: 15px 10px;
    background-size: cover;
    /* width: 539px; */
    height: 430px; 
    width:555px;
}
/* .sanskritShloka_cancelation_img{
    background: url("../../../assets/img/bg_paper.png") no-repeat;
    padding: 15px 10px;
    background-size: cover;
    
    height: 430; 
    width:100%;
} */
.sanskritShloka{
    position: absolute;
    top: 100px;
    width: 80%;
    padding-left: 50px;
   
}
.sanskritShloka p{
    font-size: 18px;
    font-weight: 600;
    color: #a00d0d;
    line-height: 1.3;
}
.sanskritResult p{
    font-size: 16px;
    font-weight: 600;
    color: black;
    line-height: 1.3;
}
.sanskritShloka, .sanskritResult{
    flex:1 1 0;
}
.nav-item.nav-link.disabled{
    background: #ee6565!important;
    opacity: 0.3;
    color: #fff!important;
}
@media only screen and (max-width: 763px) {
    .drpSelect select{
        width: 100%;
    }
}