hgroup { padding-left: 15px; border-bottom: 1px solid #ccc; margin-bottom: 10px;}
hgroup h1 { font: 500 normal 1.625em "Roboto",Arial,Verdana,sans-serif; color: #2a3644; margin-top: 0; line-height: 1.15;    text-align: start; }
hgroup h2.lead { font: normal normal 1.125em "Roboto",Arial,Verdana,sans-serif; color: #2a3644; margin: 0; padding-bottom: 10px; }
.search-result .thumbnail { border-radius: 0 !important; }
.search-result:first-child { margin-top: 0 !important; }
.search-result { margin-top: 20px; }
.search-result .col-md-3 { border-right: 1px dotted #ccc; min-height: 140px; }
.search-result ul { padding-left: 0 !important; list-style: none;  }
.search-result ul li { font: 400 normal .85em "Roboto",Arial,Verdana,sans-serif;  line-height: 30px; display: list-item;}
.search-result ul li i { padding-right: 5px; }
.search-result .col-md-7 { position: relative; }
.search-result .col-md-1 { padding: 0; }
.search-result h3 { font: 500 normal 1.375em "Roboto",Arial,Verdana,sans-serif; margin-top: 0 !important; margin-bottom: 10px !important; }
.search-result p { font: normal normal 1.125em "Roboto",Arial,Verdana,sans-serif; font-size: 14px; } 
/* .search-result span.plus a { background-color: #248dc1; padding: 5px 5px 3px 5px; }
.search-result span.plus a:hover { background-color: #414141; }
.search-result span.plus a i { color: #fff !important; } */
.search-result span.border { display: block; width: 97%; margin: 0 15px; border-bottom: 1px dotted #ccc; }

.circular-square { 
    width: 100px;
    height: 100px;
    margin-left: -22px;
    border-top-left-radius: 50% 50%;
     border-top-right-radius: 50% 50%;
      border-bottom-right-radius: 50% 50%;
       border-bottom-left-radius: 50% 50%; }

       .alphabet-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 5px; /* Add spacing between buttons */
      }
      
      .alphabet-button {
        /* Customize button styles here. For example: */
        border-radius: 5px;
        padding: 5px 10px;
        font-weight: bolder;
        margin: 10px;
        background-color: #ee6565 !important;
        color: #fff !important;
      }
