@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,400&display=swap");
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #666;
  font-family: "Lucida Bright", sans-serif;
}
.content-page {
  padding: 0 !important;
  margin-top: 72px !important;
}
.ghatak {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.righttable {
  width: 30%;
  float: left;
  padding: 0.5rem;
  font-size: 15px;
  font-weight: bold;
  border: none;
  margin-top: 30px;
}

.righttableAV {
  width: 30%;
  float: left;
  padding: 0.5rem;
  font-size: 15px;
  margin-left: -5%;
  font-weight: bold;
  border: none;
  margin-top: 30px;
}

.righttable td,
.righttable th,
.righttableAV td,
.righttableAV th {
  padding: 0.5rem;
  border: none;
  font-family: "Lucida Bright";
  white-space: nowrap;
}

.lefttable {
  width: 30%;
  float: left;
  margin-left: 0.5%;
  padding: 0.5rem;
  font-size: 15px;
  font-weight: bold;
  border: none;
  margin-top: 30px;
}
.lefttableAV {
  width: 30%;
  float: left;
  margin-left: 0.5%;
  padding: 0.5rem;
  font-size: 15px;
  font-weight: bold;
  border: none;
  margin-top: 30px;
}

.lefttableghatak {
  width: 45%;
  float: left;
  padding: 0.5rem;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  margin-top: 30px;
}

.lefttable td,
.lefttable th,
.lefttableghatak td,
.lefttableghatak th,
.lefttableAV td,
.lefttableAV th {
  padding: 0.5rem;
  border: none;
  font-family: "Lucida Bright";
  white-space: nowrap;
}

.lefttable td,
.righttable td {
  padding: 0.35rem;
}

.panel .with-nav-tabs .panel-heading {
  padding: 5px 5px 0 5px;
}
.panel .with-nav-tabs .nav-tabs {
  border-bottom: none;
}
.panel .with-nav-tabs .nav-justified {
  margin-bottom: -1px;
}

/********************************************************************/
.nav-tabs {
  border-bottom: 0px;
}
/*** PANEL WARNING ***/
.with-nav-tabs.panel-warning .nav-tabs > a,
.with-nav-tabs.panel-warning .nav-tabs > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > a:focus {
  color: #fff;
}

.tab-content {
  padding: 0px;
}
/* .tab-outer-border {
  border: 1px solid #ee6565;
  border-top-color: transparent;
} */
*/ .with-nav-tabs.panel-warning .nav-tabs > a,
.with-nav-tabs.panel-warning .nav-tabs > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > a:focus {
  color: #fff;
  background-color: #fff;
  border-color: #ee6565;
  border-bottom-color: transparent;
}
/* .with-nav-tabs.panel-warning .nav-tabs {
  border-color: #ee6565;
} */
.with-nav-tabs.panel-warning .nav-tabs > a {
  color: #ee6565;
  border-bottom-color: #ee6565;
}
.with-nav-tabs.panel-warning .nav-tabs > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > a:focus {
  background-color: #ee6565;
}
.with-nav-tabs.panel-warning .nav-tabs > .active > a,
.with-nav-tabs.panel-warning .nav-tabs > .active > a:hover,
.with-nav-tabs.panel-warning .nav-tabs > .active > a:focus {
  color: #fff;
  background-color: #8a6d3b;
}
/********************************************************************/

.reset {
  margin-left: auto;
}

.chakra-title {
  font-family: "Lucida Bright";
  font-weight: bold;
  text-align: center;
}
/* #table-Ghatak tbody tr:first-child td{
  background-color: #ffeeba!important;
  border-color: #ffdf7e!important;
} */

.left-align {
  text-align: left;

  color: #008585ff;
}

.right-align {
  text-align: right;
  color: #666666;
  white-space: nowrap;
}

.images-div-ghatak {
  float: left;
  width: 45%;
}
.ghatak-image {
  margin-left: 10%;
  margin-top: 7.5%;
}

.images-div {
  float: left;
  width: 40%;
  margin-left: 5%;
}

.images-div-basic {
  float: left;
  width: 38%;
}
.avkhada-image {
  margin-top: 5%;
}
.basic-image-asc {
  margin-top: 2.5%;
  width: 50%;
  margin-left: -5%;
}
.basic-image-rashi {
  margin-top: 2.5%;
  width: 50%;
  margin-left: 5%;
}
.basic-image-nakshtra {
  width: 30%;
  margin-left: 32%;
}

.main-chakra {
  margin-top: 20px;
}

.subheading {
  font-weight: bold;
  text-align: center;
  font-size: 1.3em;
  margin-bottom: 10px;
}
