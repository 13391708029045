table {
  border-spacing: 0;
  border: 1px solid #ededed;
  display: table;
}

.App {
  display: flex;
  flex-direction: column;
  padding: 10px;
}


* {
  font-family: sans-serif; /* Change your font family */
}

.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.93em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
}

.content-table th {
  font-weight: bolder;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  margin: 0;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.content-table tr:hover {
  box-shadow: 0 13px 48px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #009879;
  /* -webkit-transform: scale(1.15); */
  color: #fff !important;
}

.snapshotLoader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;

  margin-top: 15%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
