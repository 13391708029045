.tablesaw {
  border-collapse: separate;
}

.tablesaw td {
  border-top: none;
}

.topth {
  border-top: none;
}

.tablesaw th {
  /* border-top: dashed;
  border-top-width: 1px;
  border-color: #009879; */
  /* height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0)); */
  border-top: none;
}

.table_head_tr {
  font-size: 21px;
  background-image: none;
  border: none;
  /* color: rgb(181, 31, 116); */
  color: #0365b2;
}

.tablevalue {
  font-size: 18px;
  color: rgb(95, 95, 95);
  /* font-weight: bold; */
  font-family: "Lucida Bright";

  font-style: normal;
  /* -webkit-font-smoothing:auto; */
}

.tablelabel {
  font-size: 18px;
  color: #ff6e00;
  font-family: "Lucida Bright";
}
.TableIconMoon {
  margin-left: 170px;
  width: 30px;
}
.TableIconSun {
  margin-left: 125px;
  width: 30px;
}
.RashiIcon {
  float: left;
  margin-left: 35%;
  width: 28px;
  filter: contrast(30%);
  margin-top: 1%;
}
.RashiIcon2 {
  float: left;
  margin-left: 35%;
  width: 28px;
  filter: contrast(30%);
  margin-top: 1%;
}

.RashiIconChandra {
  float: left;
  margin-left: 45%;
  width: 20px;
  filter: contrast(30%);
  margin-top: 1%;
}

.td1 {
  text-align: right;
  font-weight: bold;
  width: 24%;
  padding: 0;
}
.td1cho {
  text-align: center;
}
.td2 {
  width: 30%;
  padding: 0;
}

.td2cho {
  margin-left: -17px;
  font-weight: bold;
  font-size: 1.4em;
  color: rgb(181, 31, 116);
}

.td3 {
  text-align: right;
  font-weight: bold;
  width: 24%;
  padding: 0;
}

.td3cho {
  text-align: center;
}

.td4 {
  width: 30%;
  padding: 0;
}

.td4cho {
  margin-left: -5px;
  font-weight: bold;
  font-size: 1.4em;
  color: rgb(181, 31, 116);
}

.Auspicious {
  background-color: #8db332;
  color: white;
  text-align: center;
  width: 80px;
  /* padding-left: 50px; */
  padding-bottom: 5px;
  /* padding-right: 50px; */
  padding-top: 5px;
  display: inline-block;
}

.Inauspicious {
  background-color: #cc3036;
  color: white;
  text-align: center;
  width: 80px;
  /* padding-left: 50px; */
  padding-bottom: 5px;
  /* padding-right: 50px; */
  padding-top: 5px;
  display: inline-block;
}

.Neutral {
  background-color: #585151;
  color: white;
  text-align: center;
  width: 80px;
  /* padding-left: 50px; */
  padding-bottom: 5px;
  /* padding-right: 50px; */
  padding-top: 5px;
  display: inline-block;
}

.VeryGood {
  margin-top: 6px;
  background-color: rgb(57, 129, 29);
  width: 80px;
  display: inline-block;
  padding-bottom: 7.5px;
  /* padding-right: 50px; */
  padding-top: 7.5px;
}

.Good {
  margin-top: 6px;
  background-color: rgb(155, 204, 58);
  width: 80px;
  display: inline-block;
  padding-bottom: 7.5px;
  padding-top: 7.5px;
}

.NotGood {
  margin-top: 6px;
  background-color: rgb(251, 214, 74);
  width: 80px;
  display: inline-block;
  padding-bottom: 7.5px;
  padding-top: 7.5px;
}
.Bad {
  margin-top: 6px;
  background-color: rgb(244, 179, 193);
  width: 80px;
  display: inline-block;
  padding-bottom: 7.5px;
  padding-top: 7.5px;
}
.VeryBad {
  margin-top: 6px;
  background-color: rgb(220, 52, 60);
  width: 80px;
  display: inline-block;
  padding-bottom: 7.5px;
  padding-top: 7.5px;
}

.tbalamheading1 {
  font-size: 1.4em;
  color: #0365b2;
  margin-left: -50px;
}

.tbalamheading2 {
  font-size: 1.4em;
  color: #0365b2;
  margin-left: -60px;
}
.td2tbalam1 {
  margin-left: -17px;
}

ul li {
  display: inline;
}

.color-box {
  width: 10px;
  height: 10px;
  float: right;
  margin-top: 0.3%;
  margin-left: 0.5%;
}

.spancolor {
  float: right;
  font-size: 12px;
  margin-right: 0.5%;
  margin-left: 0.5%;
  color: rgb(95, 95, 95);
}

/* Header */

.dpPHeaderLeftContent {
  float: left;
  white-space: nowrap;
  color: #f5fffa;
  width: 33%;
}
.dpPHeaderCenterContent {
  float: left;
  white-space: nowrap;
  color: #f5fffa;
  width: 33%;
  margin-top: 0%;
}
.dpPHeaderRightContent {
  float: right;
  white-space: nowrap;
  color: #f5fffa;
  width: 33%;
}

.dpPHeaderLeftTitle {
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  color: #f5fffa;
  margin-top: 0%;
}
.dpPHeaderLeftSubTitle {
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  color: #f5fffa;
}
.dpPHeaderRightTitle {
  font-size: 1.3rem;
  font-weight: 600;
  color: #f5fffa;
  margin-left: 30%;
  margin-top: 0%;
}
.dpPHeaderCenterTitle {
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
  color: #f5fffa;
  margin-left: 16%;
  margin-top: -1%;
}

.dpPHeaderImage {
  width: 80px;
  height: 80px;
  float: left;
  margin-top: -1%;
  margin-left: 5%;
  margin-right: 3%;
}
.dpPHeaderImage img {
  width: 100%;
}

.button-list {
  margin-left: 12%;
  margin-top: 1%;
}

.btn-primary {
  font-size: 11px;
}

.Goodchandra {
  color: rgb(57, 129, 29);
}

.Badchandra {
  color: rgb(220, 52, 60);
}

.Pujachandra {
  color: rgb(220, 52, 60);
}

.l2 {
  font-size: 16px;
}

.toggle {
  background-color: rgb(255, 255, 255);
}

.fa-plus-circle {
  cursor: pointer;
  float: right;
  margin-right: 30%;
  margin-top: 5%;
}

.fa-minus-circle {
  cursor: pointer;
  float: right;
  margin-right: 30%;
  margin-top: 5%;
}

/* Loader Animation */

#loader {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 40vw;
  top: 20vh;
  transform: translate(-50%, -50%);
}
#loader .planet-top {
  width: 70%;
  height: 70%;
  position: relative;
  top: 15%;
  left: 15%;
  z-index: 20;
  border-radius: 50%;
  background: linear-gradient(-15deg, #6bbaec 0, #6987c9 50%, transparent 51%);
  animation: blinktop 0.6s linear infinite;
}
#loader .planet-bottom {
  height: 70%;
  width: 70%;
  z-index: 80;
  position: absolute;
  background: linear-gradient(
    -15deg,
    transparent 49%,
    #6987c9 50%,
    #7880b5 100%
  );
  top: 15%;
  left: 15%;
  border-radius: 50%;
  animation: blinkbot 0.6s linear infinite;
}
#loader .ring {
  width: calc(100% - 6px);
  height: calc(100% / 3);
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-10deg);
  border: 2px solid black;
  border-radius: 50%;
  z-index: 50;
}
#loader .ring:after {
  content: "";
  position: absolute;
  top: 10%;
  left: -10%;
  width: calc(10% * 1.5);
  height: calc(33% * 1.5);
  border-radius: 50%;
  background: linear-gradient(45deg, #bcc4db, #c0a9b0);
  animation: flyaround 1.8s linear infinite;
}

@keyframes blinktop {
  0%,
  100% {
    background: linear-gradient(
      -15deg,
      #6bbaec 0,
      #6987c9 50%,
      transparent 51%
    );
  }
  33% {
    background: linear-gradient(
      -15deg,
      #6987c9 0,
      #7880b5 50%,
      transparent 51%
    );
  }
  66% {
    background: linear-gradient(
      -15deg,
      #7880b5 0,
      #6bbaec 50%,
      transparent 51%
    );
  }
}
@keyframes blinkbot {
  0%,
  100% {
    background: linear-gradient(
      -15deg,
      transparent 49%,
      #6987c9 50%,
      #7880b5 100%
    );
  }
  33% {
    background: linear-gradient(
      -15deg,
      transparent 49%,
      #7880b5 50%,
      #6bbaec 100%
    );
  }
  66% {
    background: linear-gradient(
      -15deg,
      transparent 49%,
      #6bbaec 50%,
      #6987c9 100%
    );
  }
}
@keyframes flyaround {
  0%,
  100% {
    top: 10%;
    left: -10%;
  }
  8% {
    top: 50%;
    left: 0;
  }
  20% {
    top: 85%;
  }
  25% {
    top: 80%;
  }
  30% {
    top: 75%;
  }
  40% {
    top: 45%;
    left: 90%;
  }
  50% {
    left: 95%;
  }
  60% {
    left: 90%;
    top: 0;
  }
  75% {
    left: 50%;
    top: -30%;
  }
  92% {
    left: 0;
    top: 0;
  }
}

.sectionheader {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "Lucida Bright";
}

u,
.buttonpanchang {
  font-family: "Lucida Bright";
}

hr.style-two {
  border: 0;
  height: 1.1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    #0365b2,
    rgba(0, 0, 0, 0)
  );
}
hr.style-seven {
  overflow: visible; /* For IE */
  height: 30px;
  border-style: solid;
  border-color: #0365b2cc;
  border-width: 1px 0 0 0;
  border-radius: 20px;
  margin-bottom: -20px;
  margin-top: 10px;
}
hr.style-seven:before {
  /* Not really supposed to work, but does */
  display: block;
  content: "";
  height: 30px;
  margin-top: -31px;
  border-style: solid;
  border-color: #0365b2cc;
  border-width: 0 0 1px 0;
  border-radius: 20px;
}
.rashiImages {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
